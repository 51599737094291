import { CloseIcon } from '@ui/components/core';
import { PriceMap } from '@ui/types/enums';
import React from 'react';
import ColorSwatch from '../../visualization/ColorSwatch/ColorSwatch';
import cn from '@ui/utils/cn';
import Link from 'next/link';

type FilterBadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  value: string;
  onClose?: () => void;
  closeHref?: string;
};

const promoBadgeGenerator = (value: string) => {
  if (value.includes('70')) {
    return '70% OFF';
  }
  if (value.includes('50')) {
    return '50% OFF';
  }
  if (value.includes('30')) {
    return '30% OFF';
  }
  return '';
};

const FilterBadge = React.forwardRef<HTMLDivElement, FilterBadgeProps>(
  ({ className, onClose, closeHref, value, ...props }, ref) => {
    const isColorFilter = value.startsWith('color_');
    const isSizeFilter = value.startsWith('size_');
    const formattedValue = value.includes('promo')
      ? promoBadgeGenerator(value)
      : PriceMap[value as keyof typeof PriceMap] === undefined
        ? value.split('_').slice(1).join().replace(/-/g, ' ').replace('%2F', '/')
        : PriceMap[value as keyof typeof PriceMap];

    return (
      <div
        ref={ref}
        className={cn(
          'inline-flex items-center rounded-sm h-6 px-2 space-x-2',
          'border border-black bg-[#ECB8CF]',
          'text-[13px] whitespace-nowrap',
          className,
        )}
        {...props}
      >
        {isColorFilter && (
          <div className="w-4 h-4 rounded-full border border-black bg-white relative">
            <ColorSwatch
              w="72%"
              h="72%"
              color={formattedValue}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
        <span className={isSizeFilter ? 'uppercase' : 'capitalize'}>{formattedValue}</span>
        {closeHref ? (
          <Link href={closeHref} className="inline-flex items-center justify-center" replace>
            <CloseIcon height={16} width={16} />
          </Link>
        ) : (
          <button onClick={onClose} type="button" className="inline-flex items-center justify-center">
            <CloseIcon height={16} width={16} />
          </button>
        )}
      </div>
    );
  },
);

FilterBadge.displayName = 'FilterBadge';

export default FilterBadge;
