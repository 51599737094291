import React from 'react';
import { Accordion, ActionIcon, CloseIcon, DownChevronIcon, Drawer } from '@ui/components/core';
import NotificationBadge from '../../badges/NotificationBadge/NotificationBadge';
import Toggle from '../../toggles/Toggle/Toggle';
import FilterBadge from '../../badges/FilterBadge/FilterBadge';
import { getFacetLabel, getFacetsMapping } from '@ui/utils/facets';
import { Button } from '../../buttons/Button/Button';
import cn from '@ui/utils/cn';
import { useRouter } from 'next/router';
import { Facet } from '@ui/helpers/filter';
import {
  generateFilters,
  sortFacetValues,
  updateFilters,
  getFiltersUrl,
  toggleFilter as toggleFilterHelper,
} from '@ui/helpers/filter';
import Link from 'next/link';

const slideRight = {
  in: { transform: 'translateX(0)' },
  out: { transform: 'translateX(-100%)' },
  transitionProperty: 'transform',
};

type CollectionFilterDrawerProps = {
  opened: boolean;
  onClose: () => void;
  facets: Array<Facet>;
  filters?: Array<string>;
  setFilters?: (value: string[]) => void;
};

const getColSpan = (facetLabel: string) => {
  const lookup: Record<string, string> = {
    size: 'col-span-3',
    color: 'col-span-6',
    price: 'col-span-6',
    length: 'col-span-6',
    sleeves: 'col-span-6',
  };

  if (Object.keys(lookup).includes(facetLabel.toLowerCase())) {
    return lookup[facetLabel.toLowerCase()];
  } else {
    return 'col-span-12';
  }
};

const getFilterVariant = (facetLabel: string) => {
  if (facetLabel.toLowerCase() === 'size') {
    return 'outline';
  } else if (facetLabel.toLowerCase() === 'color') {
    return 'swatch';
  } else {
    return 'radio';
  }
};

const CollectionFilterDrawer = ({ opened, onClose, facets, setFilters }: CollectionFilterDrawerProps) => {
  const router = useRouter();
  const filters = generateFilters(router);
  const [localFilters, setLocalFilters] = React.useState<string[]>(() => generateFilters(router));
  const appliedFilters = localFilters.filter((filter) => !filter.startsWith('category_'));
  React.useEffect(() => setLocalFilters(generateFilters(router)), [router]);

  const isSelected = (filterValue: string) => {
    return localFilters.some((f) => f === filterValue);
  };

  const resetFilters = () => {
    setLocalFilters([]);
  };

  const removeFilter = (filterValue: string) => {
    setLocalFilters(localFilters.filter((f) => f !== filterValue));
  };

  const toggleFilter = (filterValue: string) => {
    if (localFilters.some((filter) => filter === filterValue)) {
      setLocalFilters(localFilters.filter((f) => f !== filterValue));
    } else {
      setLocalFilters([...localFilters, filterValue]);
    }
  };

  const handleClose = () => {
    setLocalFilters(filters);
    onClose();
  };

  const header = (
    <div className="w-full flex items-center justify-between px-4">
      <span>FILTER</span>
      <ActionIcon onClick={handleClose}>
        <CloseIcon height={16} width={16} />
      </ActionIcon>
    </div>
  );

  const body = (
    <Accordion
      multiple
      chevron={<DownChevronIcon width={16} height={16} />}
      classNames={{
        control: 'text-[13px] px-0 active:bg-white hover:bg-white',
        content: 'px-0',
        item: 'border-[#E0E0E0] last-of-type:border-none',
        label: '[&>div]:w-[max-content]',
      }}
    >
      {getFacetsMapping(facets).map((facet) => (
        <Accordion.Item key={facet.label} value={facet.label}>
          <Accordion.Control disabled={facet.values.length === 0}>
            <NotificationBadge
              className="size-[0.75rem] mt-[0.24rem] mr-[-0.5rem]"
              color="brand.8"
              show={facet.values.some((fv) => localFilters.includes(fv.formattedValue))}
              animate={false}
            >
              <span className="text-[13px]">{getFacetLabel(facet.label)}</span>
            </NotificationBadge>
          </Accordion.Control>
          <Accordion.Panel>
            <div className={cn('grid grid-cols-12 gap-4', facet.label.toLowerCase() !== 'size' && 'pl-4')}>
              {sortFacetValues(facet as Facet).map((fv) => (
                <div key={fv.formattedValue} className={cn(getColSpan(facet.label))}>
                  <Link href={getFiltersUrl(router, toggleFilterHelper(router, fv.formattedValue))!}>
                    <Toggle
                      pressed={isSelected(fv.formattedValue)}
                      onPressedChange={() => toggleFilter(fv.formattedValue)}
                      variant={getFilterVariant(facet.label)}
                      value={fv.value?.toLowerCase()}
                      component="div"
                    >
                      {fv.formattedLabel}
                    </Toggle>
                  </Link>
                </div>
              ))}
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );

  const footer = (
    <div className="p-4 pt-2 shadow-[0px_0px_8px_0px_rgba(0,0,0,0.10)]">
      {appliedFilters.length > 0 && (
        <div className="overflow-auto flex space-x-2 mb-4 -mx-4 px-4">
          {appliedFilters.map((appliedFilter) => (
            <FilterBadge key={appliedFilter} value={appliedFilter} onClose={() => removeFilter(appliedFilter)} />
          ))}
        </div>
      )}
      <div className="flex space-x-4">
        <Button
          variant="outline"
          className="w-full"
          onClick={() => {
            resetFilters();
          }}
        >
          Clear All ({localFilters.length})
        </Button>
        <Button
          className="w-full"
          onClick={() => {
            onClose();

            if (setFilters) {
              return setFilters(localFilters);
            } else {
              return updateFilters(router, localFilters);
            }
          }}
        >
          APPLY
        </Button>
      </div>
    </div>
  );

  return (
    <Drawer
      header={header}
      body={body}
      footer={footer}
      opened={opened}
      onClose={handleClose}
      transitionProps={{
        duration: 250,
        timingFunction: 'ease-in-out',
        transition: slideRight,
      }}
      className="block md:hidden"
      classNames={{
        content: 'flex flex-col flex-nowrap shadow-[1px_0_0_0_rgb(0_0_0/0.5)]',
        title: 'relative flex items-center justify-center text-center h-[48px] w-full text-[13px] uppercase',
        header: 'p-0 border-b border-[#f5f5f5]',
        body: 'flex-1 px-4 py-0 bg-white overflow-y-scroll overflow-x-hidden',
      }}
    />
  );
};

export default CollectionFilterDrawer;
